import React, { Component } from 'react';
import RawTable from "./RawTable"


class CallRows extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [],
            data: []
        };
    }

    componentDidMount() {
        
    }

    updateCallRows = (history) => {

        let s = {
            columns: [
                {
                    Header: 'Site',
                    accessor: 'site',
                },
                {
                    Header: 'Wing',
                    accessor: 'wing',
                },
                {
                    Header: 'Room',
                    accessor: 'room',
                },
                {
                    Header: 'Call Type',
                    accessor: 'calltype',
                },
                {
                    Header: 'Raised Time',
                    accessor: 'raisedtime',
                },
                {
                    Header: 'Cancelled Time',
                    accessor: 'canceltime',
                },
                {
                    Header: 'Device',
                    accessor: 'device',
                },
            ]
        }
        s.data = history.map((m)=>({
            site: m.site,
            wing: m.wing,
            room: m.room,
            calltype: m.callType,
            raisedtime: m.raise,
            canceltime: m.cancelled,
            device:m.device
        }))

        this.setState(s)
        
    };

    render() {
        return (
            <RawTable columns={this.state.columns} data={this.state.data} />
        );
    }
}

export default CallRows;

