import React, { Component } from 'react';
import DateTimePicker from 'react-datetime-picker';
import { WiCloudRefresh } from 'react-icons/wi';
import CallRows from './CallRows';
import QueryCallService from "../services/QueryCallsService"
import AwsLogin from '../login/AwsLogin';

import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import './CallQuery.css';


class CallQuery extends Component {
    constructor(props) {
        super(props);
        this.callRowsRef = React.createRef();
        this.state = {
        };
    }

    componentDidMount() {
        /*if (this.callRowsRef.current) {
            QueryCallService.getCalls().then((response) => {
                this.callRowsRef.current.updateCallRows(response);
            })
        }*/
    }

    clickQuery() {
        try {
            if (this.callRowsRef.current) {
                QueryCallService.getCalls().then((response) => {
                    this.callRowsRef.current.updateCallRows(response);
                })
            }

        } catch (ex) {
            return <AwsLogin />;
        }
    }

    render() {
        return (
            <div>
                <div className="query-panel">
                    <div callName="query-timespan-panel">
                        <div className="query-timespan-combo"><span className="timespan-combo-label">Date/Time Since</span><DateTimePicker /></div>
                        <div className="query-timespan-combo"><span className="timespan-combo-label">Date/Time Until</span><DateTimePicker /></div>
                    </div>
                    <div>
                        <button className='calls-query-button' onClick={()=>this.clickQuery()}>
                            <span className="icon-wrapper">
                                <WiCloudRefresh size={60} color='#61dafb' />
                            </span>
                            <span className="button-text">Query</span>
                        </button>
                    </div>
                </div>
                <hr/>
                <div><CallRows ref={this.callRowsRef} /></div>;
            </div>
        )
    }
}

export default CallQuery;