import logo from '../logo.svg';
import logo_alexys from '../logo_alexys.jpg'
import logo_aptus from '../logo.png'
import logo_aws from '../aws_image.png'
import './Dashboard.css';
import { FaPlus } from 'react-icons/fa';


import React, { Component } from 'react';

import CallQuery from '../calls/CallQuery'



class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      message: ''
    };
  }

  componentDidMount() {
    
  }



  render() {
    

    return (
      <div className="Dashboard">
        <header className="Dashboard-header">
          <div className="alexys-logo">
            <img src={logo_aptus} alt="logo-aptus" />
          </div>
          <div className='plus-icon'>
            <FaPlus size={60} color='#61dafb' />
          </div>

          <div className="react-logo"><img src={logo} className="Dashboard-logo" alt="logo" />
          
            <a
              className="Dashboard-link"
              href="https://reactjs.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn React
            </a>
          </div>
          <div className='plus-icon'>
            <FaPlus size={60} color='#61dafb' />
          </div>
          <div className='aws-logo'><img src={logo_aws} alt="logo"/></div>
        
        </header>
        <hr/>

        <div className="CallQuery"><CallQuery/></div>

      </div>
    );
  }
}

export default Dashboard;