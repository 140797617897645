import React from 'react';

class AwsLogin extends React.Component {
  componentDidMount() {
    // Replace "newUrl" with the desired URL you want to redirect to
    const newUrl = "https://cloud-query.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize?client_id=2ahpokncqlmmdm5l642pg7gncg&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=https%3A%2F%2Fcloud.alexys.io";

    window.location.href = newUrl;
  }

  render() {
    return (
      <div>
        <h1>Redirecting...</h1>
      </div>
    );
  }
}

export default AwsLogin;
