import AccessTokenChecker from './login/AccessTokenChecker'

function App() {
  return (
    <div className="App">
           <AccessTokenChecker/>
    </div>
  );
}

export default App;
