import React, { Component } from 'react';
import AwsLogin from './AwsLogin';
import Dashboard from '../home/Dashboard';
import QueryCallService from '../services/QueryCallsService'

class AccessTokenChecker extends Component {
    componentDidMount() {
        
    }

    checkIdToken() {
        //var idToken = this.getCookie('idToken');
        //if (!idToken) {

        let idToken = QueryCallService.getURLIdToken();
        if (idToken !== null) {
            //document.cookie = `idToken=${idToken}; path=/; secure; HttpOnly`;
            return <Dashboard/>;
        } else {
            return <AwsLogin/>;
        }
        //}
    }

   

    getCookie(name) {
        const cookieString = document.cookie;
        const cookies = cookieString.split(';');

        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
                return cookie.substring(name.length + 1, cookie.length);
            }
        }

        return null;
    }

    render() {
        //return <Dashboard/>     
        return this.checkIdToken();
    }
}

export default AccessTokenChecker;
