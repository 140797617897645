

class QueryCallsService {
    static getCalls() {

        return new Promise((resolve, reject)=>{
            try {
                fetch("https://9mfxt7obnb.execute-api.ap-southeast-2.amazonaws.com/alpha/calls", {
                    headers: {
                        'Authorization': this.getURLIdToken()
                    }
                }).then((response)=>{
                    resolve(response.json())
                }).catch((err)=>{
                    reject(err)
                })
            } catch (ex) {
                reject(ex)
            }
        })
        
    }

    static getURLIdToken() {
        try {
            const url = new URL(window.location.href);
            const hashParams = new URLSearchParams(url.hash.substring(1));
            return hashParams.get('id_token')
        } catch (err) {
            return null;
        }

    }
}

export default QueryCallsService;
